import * as C from './style'

export const Footer = () => {
    return (
        <C.Footer>
            Created By <a href={'https://andreconjo.com'}
                          target={'_blank'}
                          rel="noreferrer">Andre Conjo</a>
        </C.Footer>
    )
}
